<template>
  <article class="popular-route">
    <div class="popular-route__wrapper">
      <Column>
        <div class="popular-route__image-holder">
          <router-link :to="params.link"
                       class="popular-route__image-link">
            <img class="popular-route__image"
                 :src="params.images[0]"
                 alt=""/>
          </router-link>
          <Favorite class="card__favorites"
                    :params="{id, entity, favorited}"/>
        </div>
        <Column class="popular-route__info-holder"
                justify="between">
          <div>
            <p class="popular-route__info-type">{{params.type.name || $type(params.type)}}</p>
            <div class="popular-route__info-title">
              {{params.title | countCharsInString($laptop ? 70 : 35)}}
            </div>
          </div>
          <Row class="popular-route__info-time-distance-rating"
               justify="between">
            <div class="popular-route__info-time">
              <Column v-if="params.duration">
                <Icon class="popular-route__info-icon"
                      viewport="0 0 20 20"
                      xlink="duration"/>
                <span class="popular-route__info-date">{{params.duration}}</span>
              </Column>
            </div>
            <div class="popular-route__info-distance">
              <Column class="html-insert"
                      v-if="params.subtype_id"
                      v-html="getSubTypes()">
              </Column>
            </div>
            <Column class="popular-route__info-rating">
              <Rating :params="{id: params.id,
                      type: 'trips',
                      rating: params.rating,
                      currentUserRating: params.currentUserRating}"/>
            </Column>
          </Row>
        </Column>
        <Row class="popular-route__settings"
             justify="end">
          <span class="popular-route__settings-text"
                v-on:click="$openPopupAddObject(params)">Редактировать</span>
          <Icon class="popular-route__settings-icon"
                xlink="settings"
                v-on:click.native="$openPopupAddObject(params)"
                viewport="0 0 20 20"/>
        </Row>
      </Column>
    </div>
  </article>
</template>

<script>
export default {
  name: 'PopularRouteEdit',
  props: {
    params: {
      type: Object,
      required: true,
    },
    object_type: {},
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    favorited() {
      return this?.params?.favorited;
    },
    subtype() {
      const type = this.$tripSubTypes.find((subtype) => subtype.id === this.params.subtype_id);
      return type ? type.name : '';
    },
  },
  methods: {
    getSubTypes() {
      if (!this.$tripSubTypesSVG.length || this.params.subtype_id === null) return '<div></div>';
      const div = document.createElement('div');
      div.innerHTML = this.$tripSubTypesSVG.find((svg) => svg.id === this.params.subtype_id).img;
      const svg = div.getElementsByTagName('svg')[0];
      svg.classList.add('popular-route__info-icon');
      div.getElementsByTagName('g')
        .forEach((el) => {
          const element = el;
          element.style.fill = '#cdcdcd';
        });
      return `${div.innerHTML}<span class="popular-route__info-date">${this.subtype}</span>`;
    },
  },
};
</script>
